import axios from 'axios'
import { deepCopy, errorHandler, getInitPOHeader, uniqIfNotNull, uniqIfNotNullWithKeepingIncQty } from '../helpers/utils'
import { uuid } from 'vue-uuid'

export default {
  state: () => ({
    poHeader: getInitPOHeader(),
    poStatusList: [],
    poItems: [],
    poPureItems: [],
    searchParam: null
  }),
  mutations: {
    setPOHeader (state, header) {
      state.poHeader = { ...getInitPOHeader(), ...header }
    },
    setPOStatusList (state, data) {
      state.poStatusList = data
    },
    removePOItem (state, id) {
      state.poItems = deepCopy(state.poItems.filter(ele => ele.LineId != id))
      state.poPureItems = deepCopy(state.poPureItems.filter(ele => ele.LineId != id))
    },
    clearPOItems (state) {
      state.poItems = []
      state.poPureItems = []
    },
    addPOItems (state, items) {
      const poItemsArray = [...state.poItems, ...items]
      state.poItems = uniqIfNotNullWithKeepingIncQty(poItemsArray, x => x.MasterId)
      const poPureItemsArray = [...state.poPureItems, ...items]
      state.poPureItems = uniqIfNotNull(poPureItemsArray, x => x.MasterId)
    },
    updatePOItem (state, poItem) {
      const focusItem = state.poItems.filter(item => item.LineId === poItem.LineId)[0]
      for (const [key] of Object.entries(focusItem)) {
        focusItem[key] = poItem[key]
      }
    },
    setPOItems (state, param) {
      state.poItems = param.extendPOItems
      state.poPureItems = param.poItems
    },
    pushPOItems (state, param) {
      const poItemsArray = [...state.poItems, ...param.extendPOItems]
      state.poItems = uniqIfNotNullWithKeepingIncQty(poItemsArray, x => x.MasterId)
      const poPureItemsArray = [...state.poPureItems, ...param.poItems]
      state.poPureItems = uniqIfNotNull(poPureItemsArray, x => x.MasterId)
    },
    savePOSearchParam (state, searchParam) {
      state.searchParam = searchParam
    }
  },
  actions: {
    async getPOHeader ({ commit }) {
      const paramsNextNumber = JSON.stringify({
        numberType: JSON.stringify(28), // Purchase orders
        requestedNumberSource: JSON.stringify(1) // System
      })
      try {
        const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', paramsNextNumber)
        if (data && data.Body) {
          commit('setPOHeader', {
            Number: data.Body.Number,
            PurchaseOrderId: uuid.v4()
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async insertPOItems ({ commit }, poItems) {
      const reqArray = []
      for (let idx = 0; idx < poItems.length; idx++) {
        reqArray.push(poItems[idx].MasterId)
      }
      const extendPOItems = []
      const param = JSON.stringify({
        modelIds: JSON.stringify(reqArray),
        orgId: JSON.stringify(localStorage.getItem('OrgId'))
      })
      try {
        const fetchedModels = await axios.post('/api/core/ModelsService/GetModels', param)
        for (let i = 0; i < poItems.length; i++) {
          const modelData = fetchedModels.data.Body.filter(modelDataItem => modelDataItem.Id === poItems[i].MasterId)[0]
          if (modelData) {
            extendPOItems.push({
              ...modelData,
              ...poItems[i],
              UomDescription: modelData.UomDescription
            })
          } else {
            extendPOItems.push({
              ...poItems[i]
            })
          }
        }
        commit('pushPOItems', {
          poItems: poItems,
          extendPOItems: extendPOItems
        })
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  getters: {
    poHeader (state) {
      return state.poHeader
    },
    poStatusList (state) {
      return state.poStatusList
    },
    poItems (state) {
      return state.poItems
    },
    poPureItems (state) {
      return state.poPureItems
    },
    poSearchParam (state) {
      return state.searchParam
    }
  }
}
