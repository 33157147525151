<template>
  <div id="top-bar" class="d-flex flex-wrap-reverse justify-content-between">
    <div class="d-flex">
      <ul v-if="platformNavigation" class="nav">
        <li v-if="this.TWEnabled && this.isBrandAlign" title="Asset Management" :class="(!this.$route.path.startsWith('/ehs') && !this.$route.path.startsWith('/wfm')) ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, 'tw')">Asset</a>
        </li>
        <li class="disabled" v-else-if="this.isBrandAlign && this.isEHSOnly" title="Asset Management" :class="(!this.$route.path.startsWith('/ehs') && !this.$route.path.startsWith('/wfm')) ? 'mm-active-url' : ''">
          <a id="disabled-eam" href="#" @click="disabledNavigation($event)">Asset
            <i class="material-icons">lock</i></a>
        </li>
        <li class="disabled" v-else-if="this.TWEnabled && !this.isBrandAlign && !this.isEHSOnly" :class="(!this.$route.path.startsWith('/ehs') && !this.$route.path.startsWith('/wfm')) ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, 'tw')">Asset </a>
        </li>
        <li v-else class="disabled">
          <a href="#" @click="disabledNavigation($event)">Asset <i class="material-icons">lock</i></a>
        </li>
        <li v-if="this.EHSEnabled && this.isBrandAlign" title="Safety" :class="this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, 'ehs')">Safety</a>
        </li>
        <li v-else-if="this.EHSEnabled && !this.isBrandAlign" :class="this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, 'ehs')">Safety</a>
        </li>
        <li v-else class="disabled">
          <a href="#" @click="disabledNavigation($event)">Safety <i class="material-icons">lock</i></a>
        </li>
         <li v-if="this.showWFM && this.isBrandAlign" title="WFM" :class="this.$route.path.startsWith('/wfm') ? 'mm-active-url' : ''">
          <img src="../../assets/integration-logos/align-wfm.png" class="top-logo" @click="toggleNavigation($event, 'wfm')">
        </li>
        <li v-else-if="this.showWFM && !this.isBrandAlign" :class="this.$route.path.startsWith('/wfm') ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, 'wfm')">WFM</a>
        </li>
      </ul>
    </div>
    <div class="d-flex flex-row-reverse ml-auto" style="padding-left: 30px;">
      <a data-toggle="dropdown" class="dropdown-toggle">
        <img alt="" height="25" style="margin-right: 20px;" class="rounded-circle" src="../../assets/images/profile_small.jpg">
      </a>
      <div class="dropdown-menu">
        <div class="d-flex">
          <div class="p-3">
            <img src="../../assets/images/profile_small.jpg" alt="Image" class="rounded-circle">
          </div>
          <div class="p-3">
            <ul class="list-unstyled text-dark">
              <li>{{ fullName }}</li>
              <li>{{ userName }}</li>
              <li v-if="!this.$store.getters.isSupportRedesign">
                <a href="/administration/user-preferences">{{ $t("buttons.settings") }}</a>
                <a class="ml-3" @click="logout($event)" href="#">{{ $t("requisition.editModal.logout") }}</a>
              </li>
              <li v-else>
                <a href="/administration/user-preferences">{{ $t("buttons.settings") }}</a><br>
                <a @click="openHelpandSupport($event)" href="#">{{ $t("buttons.HelpandSupport") }}</a><br>
                <a @click="logout($event)" href="#">{{ $t("requisition.editModal.logout") }}</a>
              </li>
            </ul>
            <LocaleSwitcher cssPosition="relative" />
          </div>
        </div>
      </div>
      <Notifications />
      <div >
        <div class="supportIconRow">
          <div class="button support-icon"
            :style="{ left: isNotificationIconVisible ? '-42px' : '-12px' }"
            @click="openSupportModal()">
            <i class="fa-light fa-circle-question"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex pr-4" v-if="this.isAppSwitcher">
      <a data-toggle="dropdown" class="dropdown-toggle">
        <img src="../../assets/icons/switcher_icon.svg" alt="" class="app-switcher-grid"/>
      </a>
      <div class="dropdown-menu">
        <div class="container">
          <h5>{{ $t("appSwitcher.AlignOps") }} </h5>
          <div class="flex-row mt-3">
            <ul class="list-ul-unstyled text-dark">
              <li class="mt-4">
                <a href="javascript:void(0);" @click="onAssetClicked">
                  <img src="../../assets/icons/asset_icon.png" alt="">
                    &nbsp;&nbsp;&nbsp;{{ $t("appSwitcher.AssetApp") }}
                </a>
                <a href="javascript:void(0);" @click="onAssetLearnMoreClicked" class="app-switcher-link pl-4">
                  <span class="pl-2">{{ $t("appSwitcher.LearnMore") }}</span>
                </a>
              </li>
              <li class="mt-4">
                <a href="javascript:void(0);" @click="onSafetyClicked">
                  <img src="../../assets/icons/safety_icon.png" alt="">
                  &nbsp;&nbsp;&nbsp;{{ $t("appSwitcher.SafetyApp") }}
                </a>
                <a href="javascript:void(0);" @click="onSafetyLearnMoreClicked" class="app-switcher-link">
                  <span class="pl-1">{{ $t("appSwitcher.LearnMore") }}</span>
                </a>
              </li>
              <li class="mt-4">
                <a href="javascript:void(0);" @click="onScrewClicked">
                  <img src="../../assets/icons/crew_icon.png" alt="">
                  &nbsp;&nbsp;&nbsp;{{ $t("appSwitcher.CrewApp") }}&nbsp;
                </a>
                <a href="javascript:void(0);" @click="onScrewLearnMoreClicked" class="app-switcher-link pl-4">
                  <span class="pl-2">{{ $t("appSwitcher.LearnMore") }}</span>
                </a>
              </li>
              <li class="mt-4">
                <a href="javascript:void(0);" @click="onFleetClicked">
                  <img src="../../assets/icons/fleet_icon.png" alt="">
                  &nbsp;&nbsp;&nbsp;{{ $t("appSwitcher.FleetApp") }}&nbsp;
                </a>
                <a href="javascript:void(0);" @click="onFleetLearnMoreClicked" class="app-switcher-link">
                  <span class="pl-2">{{ $t("appSwitcher.LearnMore") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LocaleSwitcher from '../shared/LocaleSwitcher'
import Notifications from '../../notifications/Notifications.vue'
import TWRoutes from '../../locales/en/platformNavigationRoutes.js'
import EHSRoutes from '../../locales/en/EHSNavigationRoutes.js'
import $ from 'jquery'
import 'floating-vue/dist/style.css'
import axios from 'axios'
import {
  errorHandler,
  clearLocalStorage,
  getLoginPath,
  routerEntitlement,
  getPlatformLoginUrl,
  trackAnalyticEvents
} from '../../helpers/utils'
export default {
  name: 'ReqEditShipTo',
  components: {
    Notifications,
    LocaleSwitcher
  },
  data () {
    return {
      TWEnabled: false,
      EHSEnabled: false,
      isBrandAlign: false
    }
  },
  methods: {
    onAssetClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'AppSwitcher Asset')
      const assetUrl = 'https://cloud.alignops.com/login'
      window.open(assetUrl, '_blank', 'noopener')
    },
    onAssetLearnMoreClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'Asset Learn More')
      const assetLearnMore = 'https://alignops.com/app-learn-more#asset'
      window.open(assetLearnMore, '_blank', 'noopener')
    },
    onSafetyClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'AppSwitcher Safety')
      const safetyUrl = 'https://admin.safety-reports.com/'
      window.open(safetyUrl, '_blank', 'noopener')
    },
    onSafetyLearnMoreClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'Safety Learn More')
      const safetyLearMore = 'https://alignops.com/app-learn-more#environment'
      window.open(safetyLearMore, '_blank', 'noopener')
    },
    onScrewClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'AppSwitcher Crew')
      const ScrewUrl = 'https://app.busybusy.io/login'
      window.open(ScrewUrl, '_blank', 'noopener')
    },
    onScrewLearnMoreClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'Crew Learn More')
      const ScrewLearnMore = 'https://alignops.com/app-learn-more#construction'
      window.open(ScrewLearnMore, '_blank', 'noopener')
    },
    onFleetClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'AppSwitcher Fleet')
      const fleetUrl = 'https://login.fleetwatcher.com/'
      window.open(fleetUrl, '_blank', 'noopener')
    },
    onFleetLearnMoreClicked () {
      trackAnalyticEvents('CloudAppSwitcher', 'Fleet Learn More')
      const fleetLearMore = 'https://alignops.com/app-learn-more#fleet'
      window.open(fleetLearMore, '_blank', 'noopener')
    },
    findEntitledRoutes (routes) {
      return routes.some(route => {
        if (!route.entitlement) {
          route.entitlement = true
        }
        if (routerEntitlement(route)) {
          return true
        }
        if (route.children) {
          return this.findEntitledRoutes(route.children, null)
        }
      })
    },
    async toggleNavigation (event, value) {
      event.preventDefault()
      this.$router.push({
        path: value === 'ehs' ? this.$store.getters.lastEHSRoute || '/ehs'
          : value === 'wfm' ? this.$store.getters.lastWFMRoute || '/wfm'
            : this.$store.getters.lastTWRoute || '/'
      }).catch((e) => e)
      this.$store.commit('setShowNavigation', value)
      if (value === 'ehs') {
        $('#top-bar .nav li:nth-child(1)').removeClass('mm-active-url')
        $('#top-bar .nav li:nth-child(2)').addClass('mm-active-url')
        $('#top-bar .nav li:nth-child(3)').removeClass('mm-active-url')
      } else if (value === 'wfm') {
        $('#top-bar .nav li:nth-child(3)').addClass('mm-active-url')
        $('#top-bar .nav li:nth-child(1)').removeClass('mm-active-url')
        $('#top-bar .nav li:nth-child(2)').removeClass('mm-active-url')
      } else {
        $('#top-bar .nav li:nth-child(2)').removeClass('mm-active-url')
        $('#top-bar .nav li:nth-child(1)').addClass('mm-active-url')
        $('#top-bar .nav li:nth-child(3)').removeClass('mm-active-url')
      }
      if (value === 'wfm') {
        this.$store.commit('setShowWorkForceNav', false)
        if (window.innerWidth < 1024) {
          $('body').removeClass('mini-navbar')
        }
      } else {
        this.$store.commit('setShowWorkForceNav', true)
      }
    },
    disabledNavigation (event) {
      event.preventDefault()
    },
    async logout (event) {
      try {
        const { status } = await axios.get('/api/logout')
        if (status === 200) {
          await this.toggleNavigation(event, 'ehs')
          setTimeout(() => {
            clearLocalStorage()
            const iframe = document.getElementById('safetyFrame')
            if (iframe) {
              iframe.src = this.baseUrl + '/admin/indexAdmin?login=1'
            }

            window.location.href = `${this.platformLoginUrl}/logout?callback_uri=${window.location.origin}&redirect_uri=${getLoginPath().replace('/', '')}`
          }, 200)
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    openHelpandSupport (event) {
      event.target.closest('div.dropdown-menu').classList.remove('show')
      $('#helpAndSupportModel').modal('show')
    },
    openSupportModal () {
      $('#helpAndSupportModel').modal('show')
    }
  },
  computed: {
    fullName () {
      return this.$store.getters.fullName
    },
    userName () {
      return this.$store.getters.userName
    },
    isAlignOpsRebrand () {
      return this.$store.getters.isAlignOpsRebrand
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    },
    baseUrl () {
      if (process.env.VUE_APP_SAFETY_URL === null || process.env.VUE_APP_SAFETY_URL === undefined) {
        return 'https://ehs.toolwatch.com'
      }
      return process.env.VUE_APP_SAFETY_URL
    },
    platformLoginUrl () {
      return getPlatformLoginUrl()
    },
    showWFM () {
      return this.$store.getters.isShowWFM
    },
    isEHSOnly () {
      return this.$store.getters.userEntitlement.includes('SafetyTierMaxAdmins') &&
      !this.$store.getters.userEntitlement.includes('T_And_E')
    },
    isAppSwitcher () {
      return this.$store.getters.isAppSwitcher
    },
    isNotificationIconVisible () {
      return this.$store.getters.isShowNotification
    }
  },
  mounted () {
    const routePath = this.$route.path.startsWith('/ehs') ? 'ehs'
      : this.$route.path.startsWith('/wfm') ? 'wfm' : 'tw'
    this.$store.commit('setShowNavigation', routePath)
    const TWRoutesClone = structuredClone(TWRoutes)
    const EHSRoutesClone = structuredClone(EHSRoutes)
    this.TWEnabled = this.findEntitledRoutes(TWRoutesClone)
    this.EHSEnabled = true
    $('.dropdown-menu').on('click', function (e) {
      e.stopPropagation()
    })
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show')
      }
      $(this).next('.dropdown-menu').toggleClass('show')
    })
    if (process.env.VUE_APP_BRAND != 'TOOLOPS') {
      this.isBrandAlign = true
      document.getElementById('top-bar').style.backgroundColor = '#20333c'
    } else {
      document.getElementById('top-bar').style.backgroundColor = '#4f555e'
    }
  }
}
</script>
<style scoped>
#top-bar {
  padding-left: 265px;
  min-height: 44px;
  position: fixed;
  width: 100%;
  z-index: 101;
  min-width: 300px;
}
body.mini-navbar #top-bar {
  padding-left: 130px;
}
body.body-small #top-bar {
  padding-left: 80px;
}
#top-bar .dropdown-toggle {
  line-height: 40px;
  z-index: 1;
}
#top-bar .dropdown-toggle span {
  color: white;
  font-weight: bold;
  margin-right: 10px
}
#top-bar .list-unstyled {
  border-bottom: 1px solid gray;
}
#top-bar .list-ul-unstyled {
  border-top: 1px solid #8080805c;
}

#top-bar .supportIconRow
{
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 15px;
}
#top-bar .support-icon {
  position: relative;
  line-height: 40px;
  cursor: pointer;
}
#top-bar .support-icon i{
  vertical-align: middle;
  font-size: 20px;
  color: white;
}
.list-ul-unstyled {
    padding-left: 0;
    list-style: none;
}
.app-switcher-link {
  margin-left: 54px;
  text-decoration: underline;
  text-align: right;
}
.app-switcher-grid {
  width: 20px;
  height: 20px;
}
#top-bar .dropdown-menu li {
  margin-bottom: 5px;
  padding-right: 10px;
}
#top-bar .dropdown-menu li:last-child {
  padding: 5px 0;
}
#top-bar .nav > li {
  border-top: 4px solid #4f555e;
  box-sizing: border-box;
}
#top-bar .nav > li.mm-active-url {
  border-top: 4px solid var(--secondary-color);
  border-left: none;
}
#top-bar .nav > li a {
  font-size: 16px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
#top-bar .nav > li a > i {
  vertical-align: middle;
  font-size: 16px;
}
#top-bar .nav > li.disabled a {
  cursor: inherit;
  color: white;
  opacity: 0.5;
}
.top-logo {
  border: 4.5px solid transparent;
  cursor: pointer;
  width: 42px;
  height: 42px;
  margin: 2px 8px;
}
.top-bar-height {
height: 53px!important;
}
#disabled-eam {
  padding: 10px 0px;
}
</style>
<style>
.modal-backdrop {
    z-index: auto !important;
}
#helpAndSupportModel {
    z-index: 2004 !important;
}
</style>
